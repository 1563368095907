import React from 'react';
import connect1 from '../../images/connect/connect1.png';
import connect2 from '../../images/connect/connect2.png';
import connect3 from '../../images/connect/connect3.png';
import connect4 from '../../images/connect/connect4.png';
import connect5 from '../../images/connect/connect5.png';

const ConnectStages = () => {
  return (
    <div className="bg-[#25AAF8] font-roboto bg-no-repeat sm:px-8 py-8">
      <div className="max-w-[1300px] mx-auto">
        <h2 className="text-[30px] font-[600] sm:text-[22px] leading-[35px] text-[#ffffff] pb-6">
          Этапы подключение
        </h2>
        <div className="grid grid-cols-5 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-6">
          {/* Step 1 */}
          <div className="bg-white rounded-xl shadow-md p-4 flex flex-col items-center text-center">
            <span className="text-[20px] font-bold flex justify-end items-end w-full text-gray-400">
              1
            </span>
            <img src={connect1} alt="Step 1" className="w-16 h-16 my-4" />
            <h3 className="text-lg font-semibold text-gray-800">Оставьте заявку</h3>
            <p className="text-gray-600 text-sm mb-4">Просто заполните форму</p>
            <a href="#/" className="text-blue-600 text-sm underline hover:text-blue-800">
              Подать заявку
            </a>
          </div>
          {/* Step 2 */}
          <div className="bg-white rounded-xl shadow-md p-4 flex flex-col items-center text-center">
            <span className="text-[20px] font-bold flex justify-end items-end w-full text-gray-400">
              2
            </span>
            <img src={connect2} alt="Step 2" className="w-16 h-16 my-4" />
            <h3 className="text-lg font-semibold text-gray-800">Дождитесь звонка</h3>
            <p className="text-gray-600 text-sm">
              Мы свяжемся с Вами в период с 8:00 до 17:00 и уточним все детали.
            </p>
          </div>
          {/* Step 3 */}
          <div className="bg-white rounded-xl shadow-md p-4 flex flex-col items-center text-center">
            <span className="text-[20px] font-bold flex justify-end items-end w-full text-gray-400">
              3
            </span>
            <img src={connect3} alt="Step 1" className="w-16 h-16 my-4" />
            <h3 className="text-lg font-semibold text-gray-800">Обработка</h3>
            <p className="text-gray-600 text-sm mb-4">
              Ваша заявка передаётся специалистам по подключению.
            </p>
          </div>
          {/* Step 4 */}
          <div className="bg-white rounded-xl shadow-md p-4 flex flex-col items-center text-center">
            <span className="text-[20px] font-bold flex justify-end items-end w-full text-gray-400">
              4
            </span>
            <img src={connect4} alt="Step 4" className="w-16 h-16 my-4" />
            <h3 className="text-lg font-semibold text-gray-800">Установка</h3>
            <p className="text-gray-600 text-sm">
              В удобное Вам время наши специалисты привезут договор, подключат Интернет и настроят
              оборудование.
            </p>
          </div>{' '}
          {/* Step 5 */}
          <div className="bg-white rounded-xl shadow-md p-4 flex flex-col items-center text-center">
            <span className="text-[20px] font-bold flex justify-end items-end w-full text-gray-400">
              5
            </span>
            <img src={connect5} alt="Step 4" className="w-16 h-16 my-4" />
            <p className="text-[20px] font-bold text-green-500">Успешно подключено</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectStages;
