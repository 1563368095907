import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const Contacts = () => {
  const offices = [
    {
      city: 'Душанбе',
      departments: [
        {
          name: 'Головной офис',
          address: '734012 Республика Таджикистан г. Душанбе, ул. Дехлави, 4',
          phone: '(+992) 44 600-60-60',
          fax: '(+992) 44 600-70-70',
          email: 'info@babilon-t.com',
          hours: {
            weekdays: '8:00 до 18:00',
            saturday: '8:00 до 17:00',
            sunday: 'выходной',
          },
          complaints: {
            title: 'Директор',
            name: 'Мазардинов Зокирҷон Мирзоевич',
            reception: 'Среда: с 14:00 до 16:00',
          },
        },
      ],
    },
    {
      city: 'Душанбе',
      departments: [
        {
          name: 'Абонентский отдел',
          address: '734012 Республика Таджикистан г. Душанбе, ул. Дехлави, 4',
          email: 'abonotdel@babilon-t.com',
          hours: {
            weekdays: '8:00 до 18:00',
            saturday: '8:00 до 17:00',
            sunday: 'выходной',
          },
          complaints: {
            title: 'Начальник депортамента обслуживания клиентов',
            name: 'Муртазоев Икром Домуллоевич',
            reception: 'Вторник: с 10:00 до 12:00',
          },
        },
      ],
    },
    {
      city: 'Худжанд',
      address: '735700 Республика Таджикистан, Согдийская область г. Худжанд, пр. И. Сомони, 76',
      phone: '(+992) 44 630-00-17, (+992) 44 630-01-06',
      fax: '(+992) 44 630-10-10',
      email: 'abonotdel@khujandi.com',
      hours: {
        weekdays: '8:00 до 17:00',
        saturday: '8:00 до 16:00',
        sunday: 'выходной',
      },
    },
    {
      city: 'Бохтар',
      address: '735140 Республика Таджикистан, Хатлонская область г. Бохтар, ул. Айни, 78',
      phone: '(+992) 44 620-01-25, (+992) 44 620-20-47',
      hours: {
        weekdays: '9:00 до 17:00',
        saturday: '8:00 до 13:00',
        sunday: 'выходной',
      },
    },
    {
      city: 'Куляб',
      address:
        '735360 Республика Таджикистан, Хатлонская область г. Куляб, ул. С. Сангова (рядом с торговым центром "Ориёно")',
      phone: '(+992) 44 625-00-01, (+992) 44 625-00-03',
      hours: {
        weekdays: '8:00 до 17:00',
        saturday: '8:00 до 15:00',
        sunday: 'выходной',
      },
    },
    {
      city: 'Хорог',
      address: '736000 Республика Таджикистан, ГБАО г. Хорог, ул. Ленина 13',
      phone: '(+992) 44 640-61-61',
      hours: {
        weekdays: '9:00 до 18:00',
        saturday: '8:00 до 13:00',
        sunday: 'выходной',
      },
    },
  ];

  return (
    <div className="bg-light min-vh-100 py-5">
      <Container className="px-4">
        <h1 className="text-start mb-5">Контакты</h1>
        <div className="mb-5 ps-2">
          <p className="mb-2 fs-5">
            <strong className="me-2">Call Center:</strong>
            <span className="text-primary">(44) 600 60 60</span>
          </p>
          <p className="mb-0 fs-5">
            <strong className="me-2">E-mail:</strong>
            <span className="text-primary">info@babilon-t.com</span>
          </p>
        </div>
        <Row xs={1} md={2} className="g-4 mb-5">
          {offices.map((office, index) => (
            <Col key={index}>
              <Card className="h-100 shadow-sm border-0">
                <Card.Header className="bg-primary text-white py-3">
                  <h2 className="h4 mb-0">Город {office.city}</h2>
                </Card.Header>
                <Card.Body className="p-4">
                  {office.departments ? (
                    office.departments.map((dept, deptIndex) => (
                      <div key={deptIndex} className={deptIndex > 0 ? 'mt-4 pt-4 border-top' : ''}>
                        <h3 className="h5 fw-bold mb-3">{dept.name}</h3>
                        <div className="mb-4">
                          <p className="text-muted mb-3">{dept.address}</p>

                          {dept.phone && (
                            <p className="mb-2">
                              <strong className="me-2">Тел:</strong>
                              <span className="text-muted">{dept.phone}</span>
                            </p>
                          )}

                          {dept.fax && (
                            <p className="mb-2">
                              <strong className="me-2">Факс:</strong>
                              <span className="text-muted">{dept.fax}</span>
                            </p>
                          )}

                          {dept.email && (
                            <p className="mb-2">
                              <strong className="me-2">E-mail:</strong>
                              <span className="text-muted">{dept.email}</span>
                            </p>
                          )}
                        </div>

                        <div className="mb-4">
                          <h4 className="h6 fw-bold mb-3">График работы:</h4>
                          <p className="mb-2 text-muted">
                            Понедельник - пятница: с {dept.hours.weekdays}
                          </p>
                          <p className="mb-2 text-muted">Суббота: с {dept.hours.saturday}</p>
                          <p className="mb-2 text-muted">Воскресенье: {dept.hours.sunday}</p>
                        </div>

                        {dept.complaints && (
                          <div className="mb-4">
                            <h4 className="h6 fw-bold mb-3">
                              Прием жалоб от физических и юридических лиц {dept.complaints.title}
                            </h4>
                            <p className="fw-bold mb-2">{dept.complaints.name}</p>
                            <p className="small text-muted mb-1">Дни приема</p>
                            <p className="text-muted">{dept.complaints.reception}</p>
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    // Render single office information
                    <>
                      <div className="mb-4">
                        <p className="text-muted mb-3">{office.address}</p>

                        {office.phone && (
                          <p className="mb-2">
                            <strong className="me-2">Тел:</strong>
                            <span className="text-muted">{office.phone}</span>
                          </p>
                        )}

                        {office.fax && (
                          <p className="mb-2">
                            <strong className="me-2">Факс:</strong>
                            <span className="text-muted">{office.fax}</span>
                          </p>
                        )}

                        {office.email && (
                          <p className="mb-2">
                            <strong className="me-2">E-mail:</strong>
                            <span className="text-muted">{office.email}</span>
                          </p>
                        )}
                      </div>

                      <div className="mb-4">
                        <h3 className="h6 fw-bold mb-3">График работы:</h3>
                        <p className="mb-2 text-muted">
                          Понедельник - пятница: с {office.hours.weekdays}
                        </p>
                        <p className="mb-2 text-muted">Суббота: с {office.hours.saturday}</p>
                        <p className="mb-2 text-muted">Воскресенье: {office.hours.sunday}</p>
                      </div>
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Contacts;
