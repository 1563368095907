import React, { Component } from 'react'
import './DomainTp.css'
import { Container } from 'react-bootstrap'

import securityImg from '../../../../images/domain/securDomain.png'
import Faq from '../../../Faq/Faq'

class DomainTp extends Component {
  render () {
    const data = [
      {
        zone: 'TJ',
        name: 'Таджикская зона',
        description: 'Самая популярная в Таджикистане',
        registration: '61,33 сомони',
        annualFee: '241 сомони / год'
      },
      {
        zone: 'COM',
        name: 'Международная зона',
        description: 'Для успешного взлёта бизнеса',
        registration: '61,33 сомони',
        annualFee: '241 сомони / год',
        annual: '?'
      },
      {
        zone: 'ORG',
        name: 'Международная зона',
        description: 'Для успешного взлёта бизнеса',
        registration: '61,33 сомони',
        annualFee: '241 сомони / год'
      },
      {
        zone: 'NET',
        name: 'Международная зона',
        description: 'Для успешного взлёта бизнеса',
        registration: '61,33 сомони',
        annualFee: '241 сомони / год'
      },
      {
        zone: 'UZ',
        name: 'Узбекская зона',
        description: 'Для успешного взлёта бизнеса',
        registration: '61,33 сомони',
        annualFee: '241 сомони / год',
        annual: '?'
      }
    ]
    return (
      <div>
        <Container>
          <div
            style={{
              marginTop: '20px',
              fontWeight: 'normal',
              marginBottom: '20px'
            }}
          >
            Интернет / Доп Услуги / Домен
          </div>

          <div className='domain-zones-container'>
            <h1>Домен зоны</h1>
            <div className='table-container'>
              <table className='domain-zones-table'>
                <thead>
                  <tr>
                    <th className='start'>Зона</th>
                    <th>Регистрация</th>
                    <th>Оплата за год</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td className='zone-cell'>
                        <div className='zone'>
                          <div
                            className={`zone-icon zone-icon-${item.zone.toLowerCase()}`}
                          >
                            {item.zone}
                          </div>
                          <div>
                            <div className='zone-name'>{item.name}</div>
                            <div className='zone-description'>
                              {item.description}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>{item.registration}</td>
                      <td>{item.annualFee}</td>
                      <td>{item.annual}</td>
                      <td className='button'>
                        <button
                          className={`apply-button apply-button-${item.zone.toLowerCase()}`}
                        >
                          Подать заявку
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className='bg-[#009df5] sm:grid sm:grid-cols-1 sm:gap-[10px] flex items-center gap-[20px] sm:h-[245px] h-[180px] justify-center p-[20px] rounded-[20px]'>
            <p className='font-bold text-[30px] sm:text-[23px] text-left text-white'>
              Проверить домен
            </p>
            <input
              type='text'
              className='w-[390px] sm:mb-[10px] sm:w-auto focus:outline-none focus:ring-[1.5px] focus:ring-gray-500 h-[54px] rounded-[20px] border-none pl-[10px]'
              value=''
              placeholder='.tj'
            />
            <button className='w-[190px] sm:w-auto h-[54px] bg-[#ed8001] border-none rounded-[20px] text-white'>
              Проверить
            </button>
          </div>
          <div className='security flex justify-between items-center'>
            <div className='text w-[65%] sm:w-[100%]'>
              <h2>Уровни безопасности доменов</h2>

              <label>
                &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Мы уделяем особое
                внимание безопасности и заботимся о стабильной работе доменов и
                сайтов наших клиентов. По умолчанию для всех доменов установлен
                «Обязательный» уровень безопасности. «Повышенный» .
              </label>
            </div>
            <div>
              <img className='sm:hidden' src={securityImg} alt='#' />
            </div>
          </div>
          <div className='securCenter'>
            <a href='#/'>Подробное описание опций безопасности</a>
          </div>
        </Container>
        <Faq />
      </div>
    )
  }
}

export default DomainTp
