import React from 'react';

const AboutCompany = () => {
  return (
    <div className="pl-[7%] pt-[20px] pb-[20px] pr-[7%] bg-gray-100 text-gray-800">
      <h1 className="text-3xl font-bold mb-4">О компании</h1>
      <p className="mb-4">
        ООО «Вавилон-Т» — крупнейший национальный оператор связи в Таджикистане интегрированных
        телекоммуникационных услуг для частных, корпоративных клиентов, а также для операторов
        связи, созданный в феврале 2000 года.
      </p>
      <p className="mb-4">
        Лозунгом компании является «Выбирайте качество», и это не зря, т.к. в поясе нового века
        глобального развития связи и телекоммуникаций, основным критерием и преимуществом компании
        становится «Качество», что является целью с самого ее образования, и этой цели компания
        будет придерживаться всегда.
      </p>
      <p className="mb-6">
        Основной задачей «Вавилон-Т» является обеспечение клиентов комплексом самых современных
        телекоммуникационных услуг, включающих услуги телефонии, передачи данных, виртуальных
        частных сетей, интеллектуальной сети связи, высокоскоростного доступа в Интернет. Клиенты
        компании имеют возможность получить у одного оператора комплексный сервис услуг. Широкий
        спектр возможностей позволяет компании предоставить клиенту любой необходимый набор услуг, с
        учетом всех его потребностей и особенностей ведения бизнеса.
      </p>
      <h2 className="text-2xl font-semibold mb-4">НАШИ ПРЕИМУЩЕСТВА:</h2>
      <ul className="list-disc list-inside mb-6">
        <li>
          «Вавилон-Т» – национальный оператор, предоставляющий полный комплекс телекоммуникационных
          услуг.
        </li>
        <li>
          «Вавилон-Т» имеет собственную цифровую инфраструктуру, которая обслуживается
          мониторинговым центром.
        </li>
        <li>
          «Вавилон-Т» имеет интегрированную сеть «NGN-телефонии», IP-сети объединенную в технологию
          «CyberNet».
        </li>
        <li>
          «Вавилон-Т» предоставляет услуги региональной передачи данных и услуги Интернет с
          использованием собственной наземной сети передачи данных.
        </li>
        <li>
          «Вавилон-Т» обеспечивает решение любых телекоммуникационных задач «под ключ», включая
          прокладывания так называемой "последней мили" (кабель к Вашему офису).
        </li>
        <li>«Вавилон-Т» предоставляет международную телефонную связь и передачи данных.</li>
        <li>Служба поддержки клиентов «Вавилон-Т» работает круглосуточно и без выходных.</li>
        <li>«Вавилон-Т» нацелен на долгосрочные отношения с клиентами.</li>
      </ul>
      <h2 className="text-2xl font-semibold mb-4">ЛИЦЕНЗИИ:</h2>
      <ul className="list-disc list-inside">
        <li>
          № 22 - 802 - РТ Предоставление услуги телематических служб (за исключением IP телефонии),
          в том числе услуги контент-провайдера (№ ‎0000802).
        </li>
        <li>
          № 22 - 801 – РТ Предоставление услуг по передаче данных (в том числе услуг телеграфной
          связи и IP телефонии) (№ ‎0000801).
        </li>
        <li>№ 22 - 812 - РТ Предоставление услуг международной телефонной связи (№ ‎0000812).</li>
        <li>№ 19 - 489 - РТ Предоставление услуг местной телефонной связи (№ ‎0000489).</li>
        <li>№ 24 - 885 - РТ Предоставление услуг междугородней телефонной связи (№ ‎0000885).</li>
        <li>
          № 23 - 839 - РТ Проектирование, строительство, эксплуатация сетей, средств связи (в том
          числе ввод в эксплуатацию каналов и трактов связи) (№ ‎0000839).
        </li>
      </ul>
    </div>
  );
};

export default AboutCompany;
