import React from 'react'
import { Container } from 'react-bootstrap'
import { FaFacebook, FaMailBulk, FaTelegram } from 'react-icons/fa'
import android from '../../images/footer/playMarket.png'
import ios from '../../images/footer/appStore.png'
import call from '../../images/footer/call.png'

const Footer = () => {
  return (
    <div className='bg-white py-8'>
      <Container>
        <div className='grid grid-cols-4 md:grid-cols-2 lg:grid-cols-3 sm:grid-cols-2 gap-8 pb-8'>
          <div className='space-y-4'>
            <h3 className='text-xl font-normal text-black mb-6'>
              Как с нами связаться
            </h3>
            <div className='space-y-2'>
              <div className='space-y-2'>
                <span className='font-semibold text-black block'>
                  Call Center
                </span>
                <div className='flex items-center gap-3'>
                  <img src={call} alt='call' className='w-6 h-6' />
                  <p className='text-2xl sm:text-xl font-bold text-[#464646] pt-2'>
                    44 600 60 60
                  </p>
                </div>
              </div>
              <div className='space-y-3 mt-4'>
                <a
                  href='mailto:Info@babilon-t.com'
                  className='flex items-center gap-4 text-black hover:text-gray-600 transition-colors'
                >
                  <FaMailBulk className='text-lg' />
                  <span>Info@babilon-t.com</span>
                </a>
                <a
                  href='https://www.facebook.com/BabilonT.Official/'
                  className='flex items-center gap-4 text-black hover:text-gray-600 transition-colors'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <FaFacebook className='text-lg' />
                  <span>Написать Facebook</span>
                </a>
                <a
                  href='https://t.me/TBabilon_bot'
                  className='flex items-center gap-4 text-black hover:text-gray-600 transition-colors'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <FaTelegram className='text-lg' />
                  <span>Написать в Telegram Bot</span>
                </a>
              </div>
            </div>
          </div>

          {/* Services Block */}
          <div className='space-y-4'>
            <h3 className='text-xl font-normal text-black mb-6'>Услуги</h3>
            <div className='flex flex-col space-y-4'>
              {[
                { href: '/home', text: 'Домашний Интернет' },
                { href: '/wimax', text: 'Технология WiMAX' },
                { href: '/lte', text: 'Технология LTE' },
                { href: '/tv', text: 'IPTV - Телевидения' },
                { href: '/ngn', text: 'NGN Телефония' },
                { href: '/hosting', text: 'Домин /Хостинг' }
              ].map(link => (
                <a
                  key={link.href}
                  href={link.href}
                  className='text-black hover:text-gray-600 transition-colors font-light'
                >
                  {link.text}
                </a>
              ))}
            </div>
          </div>

          {/* About Block */}
          <div className='space-y-4'>
            <h3 className='text-xl font-normal text-black mb-6'>Вавилон-Т</h3>
            <div className='flex flex-col space-y-4'>
              {[
                { href: '/aboutCompany', text: 'О компании' },
                { href: '/Contacts', text: 'Контакты' },
                { href: '/promotion', text: 'Акции' },
                { href: '/news', text: 'Новости' }
              ].map(link => (
                <a
                  key={link.href}
                  href={link.href}
                  className='text-black hover:text-gray-600 transition-colors font-light'
                >
                  {link.text}
                </a>
              ))}
            </div>
          </div>

          <div className='space-y-4 md:flex md:flex-col md:items-start lg:items-end'>
            <div className='flex flex-col lg:flex-col gap-4'>
              <a
                href='#/'
                className='transform hover:scale-105 transition-transform'
              >
                <img
                  src={android}
                  alt='Download on Google Play'
                  className='w-40 h-auto'
                />
              </a>
              <a
                href='#/'
                className='transform hover:scale-105 transition-transform'
              >
                <img
                  src={ios}
                  alt='Download on App Store'
                  className='w-40 h-auto'
                />
              </a>
            </div>
          </div>
        </div>
      </Container>

      <div className='border-t border-gray-200 mt-8'>
        <Container>
          <div className='py-6 text-center sm:text-left'>
            <p className='text-gray-600 text-sm sm:text-base'>
              © {new Date().getFullYear()} Все права защищены
            </p>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Footer
