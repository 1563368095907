import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const SearchResults = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('q');
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (query) {
      const mockResults = [
        { title: 'WiMAX', url: '/wimax' },
        { title: 'LTE', url: '/lte' },
        { title: 'Домашний интернет', url: '/homeinternet' },
      ];
      setResults(mockResults.filter((item) => item.title.toLowerCase().includes(query.toLowerCase())));
    }
  }, [query]);

  return (
    <div>
      <h1>Результаты поиска: {query}</h1>
      {results.length > 0 ? (
        results.map((result, index) => (
          <div key={index}>
            <a href={result.url}>{result.title}</a>
          </div>
        ))
      ) : (
        <p>Ничего не найдено.</p>
      )}
    </div>
  );
};

export default SearchResults;