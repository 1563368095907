import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import './Tarrifs.css'
import Dushanbe from './Dushanbe/Dushanbe'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

class Tarrifs extends Component {
  constructor (props) {
    super(props)
    this.state = { selectedCity: 'dushanbe' } 
    this.handleCityChange = this.handleCityChange.bind(this)
  }

  handleCityChange (event) {
    const newCity = event.target.value
    this.setState({ selectedCity: newCity })
    console.log(newCity) // Log selected city
  }

  renderCityInfo () {
    switch (this.state.selectedCity) {
      case 'dushanbe':
        return (
          <div>
            <p className='font-[700] text-[24px]'>Душанбе</p>
            <Dushanbe />
          </div>
        )
      case 'khujand':
        return (
          <div>
            <p className='font-[700] text-[24px]'>Худжанд</p>
            <Dushanbe />
          </div>
        )
      case 'kulyab':
        return (
          <div>
            <p className='font-[700] text-[24px]'>Куляб</p>
            <Dushanbe />
          </div>
        )
      case 'kurgantube':
        return (
          <div>
            <p className='font-[700] text-[24px]'>Курган-Тюбе</p>
            <Dushanbe />
          </div>
        )
      default:
        return (
          <p className='font-[700] text-[24px]'>Пожалуйста, выберите город.</p>
        )
    }
  }

  render () {
    return (
      <>
        <Container>
          <div className='selectCity sm:flex sm:px-4 sm:flex-col'>
            <p className='text-[30px] mr-[10px] font-[900] sm:text-[20px] sm:font-[600]'>
              Домашний интернет для{' '}
            </p>
            <FormControl sx={{ minWidth: 120, border: 'none' }} size='small'>
              <Select
                id='city-select'
                value={this.state.selectedCity}
                onChange={this.handleCityChange}
                sx={{
                  '& .MuiSelect-select': {
                    color: 'orange',
                    fontSize: '1.6rem'
                  },
                  '& fieldset': { border: 'none' }
                }}
              >
                <MenuItem value='dushanbe'>г.Душанбе</MenuItem>
                <MenuItem value='khujand'>г.Худжанд</MenuItem>
                <MenuItem value='kulyab'>г.Куляб</MenuItem>
                <MenuItem value='kurgantube'>г.Курган-Тюбе</MenuItem>
              </Select>
            </FormControl>
          </div>
          {this.renderCityInfo()}
        </Container>
      </>
    )
  }
}

export default Tarrifs
