import React, { useEffect, useState } from 'react'
import { URL } from '../../url'

const Equipment = () => {
  const [eqpt, setEqpt] = useState(null)
  const [hoveredId, setHoveredId] = useState(null)

  useEffect(() => {
    const getEqpt = async () => {
      try {
        const response = await fetch(`${URL}/api/eqpt`)
        const data = await response.json()
        setEqpt(data)
      } catch (error) {
        console.error(
          'Ошибка при получении данных оборудования:',
          error.message
        )
      }
    }

    getEqpt()
  }, [])

  const getStyles = elementId => {
    const isId3 = elementId === 3
    const isHovered = hoveredId === elementId
    let borderStyle = '1px solid #b3b2b2'
    if (isId3) {
      if (!hoveredId) {
        borderStyle = '2px solid #495AFF'
      } else if (isHovered) {
        borderStyle = '2px solid #495AFF'
      }
    } else if (isHovered) {
      borderStyle = '2px solid #495AFF'
    }
    const buttonStyle =
      (isId3 && !hoveredId) || isHovered ? 'bg-[#495AFF]' : 'bg-black'

    return { borderStyle, buttonStyle }
  }

  return (
    <div className='container mx-auto px-4'>
      <h2 className='pt-6 sm:px-6'>Оборудование</h2>
      <div className='grid grid-cols-4 sm:px-6 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 pt-5 gap-2'>
        {eqpt?.map(el => {
          const { borderStyle, buttonStyle } = getStyles(el.id)

          return (
            <div
              key={el.id}
              style={{ border: borderStyle }}
              className='bg-white p-3 transition duration-300 ease-in-out group rounded-xl mb-8'
              onMouseEnter={() => setHoveredId(el.id)}
              onMouseLeave={() => setHoveredId(null)}
            >
              <div className='flex items-center justify-center'>
                <img
                  src={`${URL}${el.image}`}
                  alt={el.modele}
                  className='h-[180px] w-[180px]'
                />
              </div>
              <span className='subTitle'>{el.modele}</span>
              <p className='font-extrabold text-xl'>{el.type}</p>
              <div className='flex items-center justify-between'>
                <p className='text-sm'>Скорость соединения:</p>
                <p className='font-semibold'>до {el.bandwidth} Мбит/с</p>
              </div>
              <div className='flex items-center justify-between'>
                <p>Площадь покрытия:</p>
                <p className='font-semibold'>до {el.coverage} кв.м</p>
              </div>
              <div className='flex items-center justify-between'>
                <p>Поддерживает:</p>
                <p className='font-semibold'>до {el.supports} устройств</p>
              </div>
              <div className='flex items-center mt-20 mb-5 justify-between'>
                <div>
                  <div className='flex items-end gap-1'>
                    <p className='font-semibold text-2xl'>{el.price}</p>
                    <p className='pb-1 font-semibold'>сом</p>
                  </div>
                </div>
                <div>
                  <button
                    className={`h-11 w-40 shadow-xl shadow-gray-300 rounded-2xl text-white transition duration-300 ease-in-out ${buttonStyle}`}
                  >
                    Заказать
                  </button>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Equipment
