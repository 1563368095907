import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import Slider from 'react-slick'
import { URL } from '../url'
import './CarouselStyle.css'

const Carouselbox = () => {
  const [slides, setSlides] = useState([])
  const [loading, setLoading] = useState(true)
  let sliderRef = useRef(null)

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const response = await axios.get(`${URL}/api/swiper-data`)
        setSlides(response.data)
        setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }

    fetchSlides()
  }, [])

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  }

  return (
    <div className='relative'>
      <Slider ref={slider => (sliderRef = slider)} {...settings}>
        {loading ? (
          <div>
            <div className='text-center'>Loading...</div>
          </div>
        ) : (
          slides.map((el, index) => (
            <div key={index} className='relative'>
              <img
                src={`${URL}${el.image}`}
                className='object-cover'
                alt={el.title}
              />
            </div>
          ))
        )}
      </Slider>
    </div>
  )
}

export default Carouselbox
