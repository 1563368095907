import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fontsource/roboto'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/400-italic.css'
import '@fontsource/noto-sans'
import '@fontsource/noto-sans/400.css'
import '@fontsource/noto-sans/400-italic.css'
import NavScrollExample from './Components/Header1'
import TextLinkNavbar from './Components/topNav'
import Footer from './Components/Footer/Footer'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './Pages/Home'
// import Telephone from "./Componenets/Business/ipTelephone/Telephone";
import Tv from './Pages/Tv'
import Ngn from './Pages/Ngn'
import Wimax from './Pages/Wimax'
import Promotion from './Pages/Promotion'
import Homeinternet from './Pages/Homeinternet'
import ConnectRequest from './Pages/ConnectRequest'
import Lte from './Pages/Lte'
import Domain from './Pages/Domain'
import Hosting from './Pages/Hosting'
import Vps from './Pages/Vps'
import AboutCompany from './Pages/AboutCompany'
import Contacts from './Pages/Contacts'
import AllNews from './Pages/AllNews'
import BusinessHome from './Components/Business/BussinessHome/BusinessHome'
import TariffsBusiness from './Components/Business/Home/Home'
import SearchResults from './Pages/SearchResults'

function App () {
  return (
    <div>
      <Router>
        <TextLinkNavbar />
        <NavScrollExample />
        <Routes>
          <Route exact path='/home' Component={Home} />
          <Route exact path='/' Component={Home} />
          <Route exact path='/business' Component={BusinessHome} />
          <Route exact path='/business/tariffs' Component={TariffsBusiness} />
          <Route exact path='/tv' Component={Tv} />
          <Route exact path='/Contacts' Component={Contacts} />
          <Route exact path='/aboutCompany' Component={AboutCompany} />
          <Route exact path='/ngn' Component={Ngn} />
          <Route exact path='/wimax' Component={Wimax} />
          <Route exact path='/promotion' Component={Promotion} />
          <Route exact path='/Homeinternet' Component={Homeinternet} />
          <Route
            exact
            path='/connection/:tariffName'
            Component={ConnectRequest}
          />
          <Route exact path='/lte' Component={Lte} />
          <Route exact path='/domain' Component={Domain} />
          <Route exact path='/hosting' Component={Hosting} />
          <Route exact path='/vps' Component={Vps} />
          <Route exact path='/business/domain' Component={Domain} />
          <Route exact path='/business/hosting' Component={Hosting} />
          <Route exact path='/business/vps' Component={Vps} />
          <Route path='/search' element={<SearchResults />} />
          {/* <Route exact path="/business/ngn" Component={Telephone} /> */}
          <Route exact path='/news' Component={AllNews} />
        </Routes>
        <Footer />
      </Router>
    </div>
  )
}
export default App
