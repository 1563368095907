import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Myip from './Myip';

function TextLinkNavbar() {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  // Обновляем текущий путь при изменении страницы
  useEffect(() => {
    const handlePathChange = () => setCurrentPath(window.location.pathname);
    window.addEventListener('popstate', handlePathChange);
    return () => window.removeEventListener('popstate', handlePathChange);
  }, []);

  return (
    <Navbar bg="light" data-bs-theme="light" className="main-navbar">
      <Container className="flex justify-between items-center gap-4">
        <div className="sm:hidden flex justify-between space-x-4">
          <a
            href="/home"
            className={`text-gray-700 font-sans hover:text-black ${
              currentPath === '/home' ? 'font-bold text-black' : ''
            }`}
          >
            Для дома
          </a>
          <a
            href="/business"
            className={`text-gray-700 font-sans hover:text-black ${
              currentPath === '/business' ? 'font-bold text-black' : ''
            }`}
          >
            Для бизнеса
          </a>
        </div>

        <select
          className="hidden sm:flex border font-sans border-gray-300 rounded-md p-2"
          value={currentPath}
          onChange={(e) => {
            const newPath = e.target.value;
            window.location.href = newPath;
            setCurrentPath(newPath);
          }}
        >
          <option value="/home">Для дома</option>
          <option value="/business">Для бизнеса</option>
        </select>

        <div className="flex font-sans items-center gap-[15px]">
          <a href="#home" className="text-gray-700 hover:text-black">
            Taj
          </a>
          <span>|</span>
          <a href="#features" className="text-gray-700 font-sansТелевидениеТелевидение hover:text-black">
            Rus
          </a>
          <span className="youIp">
            <Myip />
          </span>
        </div>
      </Container>
    </Navbar>
  );
}

export default TextLinkNavbar;
