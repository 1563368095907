import React, { useState, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import logo from '../images/logo.png'
import login from '../images/Login.png'
import './Header.css'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import '@szhsin/react-menu/dist/index.css'

import { ControlledMenu, MenuItem, useHover, SubMenu } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/zoom.css'
import { CloseCircleOutlined } from '@ant-design/icons'

function NavScrollExample () {
  const location = useLocation()
  const navigate = useNavigate()
  const isBusinessMenuVisible = location.pathname.includes('/business')

  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const handleSearch = e => {
    const query = e.target.value
    setSearchQuery(query)

    if (query.trim() === '') {
      setSearchResults([])
      return
    }
    const pageText = document.body.textContent
    if (pageText.includes(query)) {
      setSearchResults([
        { title: 'Найдено на текущей странице', url: window.location.pathname }
      ])
    } else {
      setSearchResults([
        { title: 'Результаты поиска', url: `/search?q=${query}` }
      ])
    }
  }
  const handlePaste = e => {
    const pastedText = e.clipboardData.getData('text')
    setSearchQuery(pastedText)
    handleSearch({ target: { value: pastedText } })
  }
  const handleSearchResultClick = url => {
    navigate(url)
    setTimeout(() => {
      const existingHighlights = document.querySelectorAll(
        'span[data-search-highlight]'
      )
      existingHighlights.forEach(highlight => {
        const parent = highlight.parentNode
        parent.replaceChild(
          document.createTextNode(highlight.textContent),
          highlight
        )
      })
      const searchRegex = new RegExp(
        searchQuery.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
        'gi'
      )
      const highlightTextNode = textNode => {
        const text = textNode.textContent
        const matches = text.match(searchRegex)

        if (!matches) return null

        const fragment = document.createDocumentFragment()
        let lastIndex = 0

        text.replace(searchRegex, (match, offset) => {
          if (offset > lastIndex) {
            fragment.appendChild(
              document.createTextNode(text.slice(lastIndex, offset))
            )
          }
          const highlight = document.createElement('span')
          highlight.style.backgroundColor = 'yellow'
          highlight.setAttribute('data-search-highlight', 'true')
          highlight.textContent = match
          fragment.appendChild(highlight)

          lastIndex = offset + match.length
        })
        if (lastIndex < text.length) {
          fragment.appendChild(document.createTextNode(text.slice(lastIndex)))
        }

        return fragment
      }
      const walker = document.createTreeWalker(
        document.body,
        NodeFilter.SHOW_TEXT,
        {
          acceptNode: node => {
            if (
              node.parentElement?.tagName === 'SCRIPT' ||
              node.parentElement?.tagName === 'STYLE'
            ) {
              return NodeFilter.FILTER_REJECT
            }
            return NodeFilter.FILTER_ACCEPT
          }
        }
      )

      const nodesToReplace = []
      while (walker.nextNode()) {
        const node = walker.currentNode
        const highlightedFragment = highlightTextNode(node)
        if (highlightedFragment) {
          nodesToReplace.push([node, highlightedFragment])
        }
      }
      nodesToReplace.forEach(([original, replacement]) => {
        original.parentNode.replaceChild(replacement, original)
      })
      const firstHighlight = document.querySelector(
        'span[data-search-highlight]'
      )
      if (firstHighlight) {
        firstHighlight.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      }
    }, 100)
  }

  const ref = useRef(null)
  const [isOpen, setOpen] = useState(false)
  const { anchorProps, hoverProps } = useHover(isOpen, setOpen)

  const refInternet = useRef(null)
  const [isOpenInternet, setOpenInternet] = useState(false)
  const { anchorProps: anchorPropsInternet, hoverProps: hoverPropsInternet } =
    useHover(isOpenInternet, setOpenInternet)

  return (
    <Navbar collapseOnSelect expand='lg' className='custom-navbar'>
      <Container>
        <Navbar.Brand href='/home'>
          <img
            src={logo}
            height='50'
            width='128'
            className='d-inline-block align-top'
            alt='logo'
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='navbarScroll' />
        <Navbar.Collapse id='navbarScroll'>
          <Nav
            className='me-auto my-2 my-lg-0'
            style={{ maxHeight: '370px' }}
            navbarScroll
          >
            {isBusinessMenuVisible ? (
              <>
                <Nav.Link ref={ref} {...anchorProps}>
                  Для бизнеса <ArrowDropDownIcon />
                </Nav.Link>
                <ControlledMenu
                  {...hoverProps}
                  state={isOpen ? 'open' : 'closed'}
                  anchorRef={ref}
                  onClose={() => setOpen(false)}
                >
                  <p className='ml-[15px] mt-[5px] font-sans text-[grey]'>
                    Интернет
                  </p>
                  <MenuItem
                    className='font-sans mt-[-15px] text-[black]'
                    href='/business/tariffs'
                  >
                    Тарифы
                  </MenuItem>
                  <MenuItem className='no-underline font-sans text-[black]'>
                    Оборудование
                  </MenuItem>
                </ControlledMenu>
              </>
            ) : (
              <>
                <Nav.Link ref={refInternet} {...anchorPropsInternet}>
                  <div className='flex'>
                    <p className='text-[18px] font-sans'>Интернет</p>
                    <div className='pt-[2px]'>
                      <ArrowDropDownIcon />
                    </div>
                  </div>
                </Nav.Link>
                <ControlledMenu
                  {...hoverPropsInternet}
                  state={isOpenInternet ? 'open' : 'closed'}
                  anchorRef={refInternet}
                  onClose={() => setOpenInternet(false)}
                >
                  <SubMenu label='Домашний интернет'>
                    <MenuItem>
                      <NavDropdown.Item href='/Homeinternet'>
                        Тарифы
                      </NavDropdown.Item>
                    </MenuItem>
                    <MenuItem>
                      <NavDropdown.Item className='text ' href='#'>
                        Оборудование
                      </NavDropdown.Item>
                    </MenuItem>
                    <MenuItem>
                      <NavDropdown.Item className='text' href='#action5'>
                        Годовые Пакеты
                      </NavDropdown.Item>
                    </MenuItem>
                    <MenuItem>
                      <NavDropdown.Item className='text' href='#action5'>
                        Турбо скорость
                      </NavDropdown.Item>
                    </MenuItem>
                  </SubMenu>

                  <SubMenu label='Технология WiMAX'>
                    <MenuItem>
                      <NavDropdown.Item href='/wimax'>Тарифы</NavDropdown.Item>
                    </MenuItem>
                    <MenuItem>
                      <NavDropdown.Item className='text' href='.wimax#12'>
                        Оборудование
                      </NavDropdown.Item>
                    </MenuItem>
                    <MenuItem>
                      <NavDropdown.Item className='text' href='#action5'>
                        Пакеты
                      </NavDropdown.Item>
                    </MenuItem>
                  </SubMenu>

                  <SubMenu label='Технология LTE'>
                    <MenuItem>
                      <NavDropdown.Item href='/lte'>Тарифы</NavDropdown.Item>
                    </MenuItem>
                    <MenuItem>
                      <NavDropdown.Item className='text' href='/lte#13'>
                        Оборудование
                      </NavDropdown.Item>
                    </MenuItem>
                    <MenuItem>
                      <NavDropdown.Item className='text' href='#action5'>
                        Годовые Пакеты
                      </NavDropdown.Item>
                    </MenuItem>
                    <MenuItem>
                      <NavDropdown.Item className='text' href='#action5'>
                        Турбо скорость
                      </NavDropdown.Item>
                    </MenuItem>
                  </SubMenu>
                </ControlledMenu>
              </>
            )}
            {isBusinessMenuVisible ? (
              <>
                <Nav.Link href='/business/ngn'>Телефония</Nav.Link>
                <Nav.Link href='/business/vps'>Виртуальный сервер</Nav.Link>
                <Nav.Link href='/business/domain'>Домен</Nav.Link>
                <Nav.Link href='/business/hosting'>Хостинг</Nav.Link>
              </>
            ) : (
              <>
                <Nav.Link href='/tv'>
                  <p className='text-[18px] font-sans'>Телевидение</p>
                </Nav.Link>
                <Nav.Link href='/ngn'>
                  <p className='text-[18px] font-sans'>Телефония</p>
                </Nav.Link>

                <Nav.Link ref={ref} {...anchorProps}>
                  <div className='flex'>
                    <p className='text-[18px] font-sans'> Доп.услуги</p>
                    <div className='pt-[2px]'>
                      <ArrowDropDownIcon />
                    </div>
                  </div>
                </Nav.Link>
                <ControlledMenu
                  {...hoverProps}
                  state={isOpen ? 'open' : 'closed'}
                  anchorRef={ref}
                  onClose={() => setOpen(false)}
                >
                  <MenuItem
                    className='no-underline font-sans text-[black]'
                    href='/domain'
                  >
                    Домен
                  </MenuItem>
                  <MenuItem
                    className='no-underline font-sans text-[black]'
                    href='/hosting'
                  >
                    Хостинг
                  </MenuItem>
                  <MenuItem
                    className='no-underline font-sans text-[black]'
                    href='/vps'
                  >
                    Виртуальный сервер VPS
                  </MenuItem>
                </ControlledMenu>
                <Nav.Link href='/promotion'>
                  <p className='text-[18px] font-sans'>Акции</p>
                </Nav.Link>
              </>
            )}
          </Nav>

          <Form className='d-flex'>
            <Form.Control
              type='search'
              placeholder='Search'
              className='me-2'
              aria-label='Search'
              value={searchQuery}
              onChange={handleSearch}
              onPaste={handlePaste}
            />
            {searchQuery && (
              <CloseCircleOutlined
                className='cursor-pointer'
                onClick={() => {
                  setSearchQuery('')
                  setSearchResults([])
                }}
              />
            )}
          </Form>
          {searchResults.length > 0 && (
            <div className='search-results'>
              {searchResults.map((result, index) => (
                <div key={index}>
                  <a
                    href={result.url}
                    onClick={e => {
                      e.preventDefault()
                      handleSearchResultClick(result.url)
                    }}
                  >
                    {result.title}
                  </a>
                </div>
              ))}
            </div>
          )}
          <div className='flex items-center'>
            <Nav.Link href='https://arm.babilon-t.com' target='_blank'>
              <p className='underline pt-[10px] pl-[10px] font-sans text-[18px]'>
                Личный кабинет
              </p>
            </Nav.Link>
            <img
              src={login}
              height='24.68'
              width='24'
              className='ml-[15px]'
              alt='logo'
            />
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavScrollExample

// Write for my header the adaptive for
